/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.snack-bar-success {
    background: #cdf8f1 !important;
    color: #234e52 !important;
}

.snack-bar-error {
    background: #f56565 !important;
}
.mat-simple-snackbar-action {
    color: #fff !important;
}

.mat-tooltip {
    font-size: 12px;
}
